import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },

  {
    path: "/home",
    name: "home",
    meta: {
      name: "首页",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    meta: {
      name: "关于我们",
    },
    // redirect: "/aboutUs/companyProfile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutUs/aboutUs.vue"),
    // children: [
    //   {
    //     path: "companyProfile",
    //     name: "companyProfile",
    //     meta: {
    //       name: "公司简介",
    //     },
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "about" */ "../views/aboutUs/companyProfile.vue"
    //       ),
    //   },
    // ],
  },
  {
    path: "/aboutUs/recruitInfo",
    name: "recruitInfo",
    meta: {
      name: "岗位详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/aboutUs/recruitInfo.vue"
      ),
  },
  {
    path: "/onlineService",
    name: "onlineService",
    meta: {
      name: "在线服务",
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/onlineService/index.vue"
      ),
  },
  {
    path: "/services",
    name: "services",
    meta: {
      name: "服务与产品",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/services/services.vue"),
  },
  {
    path: "/solution",
    name: "solution",
    meta: {
      name: "解决方案",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/solution/solution.vue"),
    // import(/* webpackChunkName: "about" */ "../views/services/services.vue"),
  },
];
//添加以下代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "hash",
  // mode:'history',
  // base: process.env.BASE_URL,
  routes,
});

export default router;
