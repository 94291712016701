import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/theme.scss";
import "@/assets/css/style.css";
import { Carousel, CarouselItem, Tooltip, Pagination } from "element-ui";
import axios from "axios";
import Meta from "vue-meta";
import ElementUI from "element-ui";

Vue.use(Meta);

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "1GmT3RBHiHDNxiiNNtXT7ijfL6dNHZyg",
});
Vue.use(ElementUI);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tooltip);
Vue.use(Pagination);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios; // 将axios挂载到原型上方便使用

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  // document.title = to.meta.name ? to.meta.name:""
});
