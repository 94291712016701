<template>
  <div id="app">
    <router-view />
    <!-- <footer-vue></footer-vue> -->
  </div>
</template>
<script>
// import navbar from "@/components/navbar.vue";
// import links from "@/components/links.vue";
// import footerVue from "@/components/footer.vue";
export default {
  name: "app",
  // components: {
  //   footerVue,
  // },
  data() {
    return {};
  },
  // watch: {
  //   $route: {
  //     header(route) {
  //       if (route == "e-mail") {
  //         this.type = 1;
  //       }
  //       console.log("我看看", this.$route.path);
  //     },
  //   },
  // },
  metaInfo: {
    title: "云创智通",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no",
      },
      { name: "keywords", content: "云创" }, //网页关键字
      { name: "description", content: "云创" }, //网页描述
    ],
  },
};
</script>
